import { API_ROUTE_LIVE_STREAM } from "@constants";
import { useRequest } from "./useRequest";

export interface ILiveStream {
  slug: string;
  isLive: boolean;
  title: string;
  summary: string;
  thumbnail: string;
}

export interface ILiveStreamResponse {
  liveStream: ILiveStream | null;
  isLoading: boolean;
  errorMessage: string;
}

function useLiveStream(slug: string): ILiveStreamResponse {
  const { data, isLoading, errorMessage } = useRequest<ILiveStream>({
    url: `${API_ROUTE_LIVE_STREAM}${slug}`,
    method: "GET",
  });

  return { liveStream: data?.liveUser || null, isLoading, errorMessage };
}

export default useLiveStream;

import { API_ROUTE_LIVE_LIST } from "@/constants";
import { useRequest } from "./useRequest";
import { ILiveStream } from "./useLiveStream";

export interface ILiveListResponse {
  liveList: ILiveStream[] | null;
  isLoading: boolean;
  errorMessage: string;
}

export function useLiveList(): ILiveListResponse {
  const { data, isLoading, errorMessage } = useRequest<ILiveStream[]>({
    url: API_ROUTE_LIVE_LIST,
    method: "GET",
  });

  return { liveList: data?.liveUsers, isLoading, errorMessage };
}

export function useIsAnyoneLive() {
  const { liveList } = useLiveList();
  return liveList && liveList.length > 0;
}

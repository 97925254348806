import { useEffect } from "react";
import config from "@config";
import {
  useManageVodsList,
  IManageVodsListResponse,
} from "@hooks/live/useManageVodsList";
import loggedInTemplate from "@components/HOC/templates/loggedInTemplate";
import { withLiveAuth } from "@components/HOC/withAuth";
import SpinnerLoader from "@components/SpinnerLoader";
import EmptyList from "@components/lists/EmptyList";
import VodManageItem from "@components/lists/VodManageItem";

function VodsManage() {
  const { vodsList, isLoading, errorMessage }: IManageVodsListResponse =
    useManageVodsList();

  useEffect(() => {
    document.title = `${config.public.appName} - Gestionar VODs`;
  }, []);

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
        <div className="container mx-auto p-6">
          {errorMessage && (
            <div className="mb-4 p-2 bg-red-100 border border-red-400 text-red-700 rounded">
              {errorMessage}
            </div>
          )}
          {!vodsList || isLoading ? (
            <SpinnerLoader />
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 overflow-x-auto md:overflow-x-visible">
              {vodsList.length === 0 ? (
                <EmptyList title="No hay videos disponibles">
                  No hay videos disponibles. Por favor, vuelve a intentarlo más
                  tarde.
                </EmptyList>
              ) : (
                vodsList.map((vod) => <VodManageItem vod={vod} key={vod.id} />)
              )}
            </div>
          )}
        </div>
      </main>
    </div>
  );
}

export default withLiveAuth(loggedInTemplate(VodsManage));

import React from "react";

interface HeaderBarProps {
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isOpen: boolean) => void;
}

const HeaderBar: React.FC<HeaderBarProps> = ({
  isSidebarOpen,
  setIsSidebarOpen,
}) => {
  // const [dropdownOpen, setDropdownOpen] = useState(false);
  // const userStore = useUserStore();
  // const authStore = useAuthStore();

  return (
    <header className="flex items-center justify-between min-h-[4.1rem] px-6 py-4 bg-white border-b-2 border-primary-x-dark">
      <div className="flex items-center">
        <button
          className="text-gray-500 focus:outline-none lg:hidden"
          onClick={() => setIsSidebarOpen(true)}
        >
          <svg
            className="w-6 h-6"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 6H20M4 12H20M4 18H11"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>

      {/* <div className="flex items-center">
        <div className="relative flex">
          {" "}
          {userStore.myUser?.picture ? (
            <div
              className="w-8 h-8 mr-2 cursor-pointer"
              onClick={() => setDropdownOpen(true)}
            >
              <img
                className="rounded-full"
                src={userStore.myUser.picture}
                alt="Profile"
              />
            </div>
          ) : (
            <div
              className="w-8 h-8 mr-5 cursor-pointer bg-primary-dark rounded-full flex items-center justify-center"
              onClick={() => setDropdownOpen(true)}
            >
              <img
                src={ISOTYPE_COLOR}
                alt="logo"
                className="w-4/5 h-4/5 object-contain"
              />
            </div>
          )}
          {dropdownOpen && (
            <div
              className="fixed inset-0 z-10 w-full h-full"
              onClick={() => setDropdownOpen(false)}
            />
          )}
          <ul
            className={`absolute right-0 z-20 w-48 py-2 mt-2 bg-white rounded-md shadow-xl transition-all duration-150 ease-in-out transform ${
              dropdownOpen
                ? "scale-100 opacity-100"
                : "scale-95 opacity-0 pointer-events-none"
            }`}
          >
            <li>
              <Link to={WEB_ROUTE_PROFILE}>Profile</Link>
            </li>
            <li>
              <button onClick={() => authStore.logout()}>Logout</button>
            </li>
          </ul>
        </div>
      </div> */}
    </header>
  );
};

export default HeaderBar;

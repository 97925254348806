import { useEffect } from "react";
import { Link } from "react-router-dom";
import config from "@config";
import { API_BASE_URL } from "@constants";
import { DEFAULT_VOD_THUMBNAIL } from "@constants/images";
import { useLiveList, ILiveListResponse } from "@hooks/useLiveList";
import loggedInTemplate from "@components/HOC/templates/loggedInTemplate";
import { withAuth } from "@components/HOC/withAuth";
import SpinnerLoader from "@components/SpinnerLoader";
import EmptyList from "@components/lists/EmptyList";

function LiveList() {
  const { liveList, isLoading, errorMessage }: ILiveListResponse =
    useLiveList();

  useEffect(() => {
    document.title = `${config.public.appName} - Live`;
  }, []);

  return (
    <div className="flex-1 flex flex-col overflow-hidden">
      <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
        <div className="container mx-auto p-6">
          {errorMessage && (
            <div className="mb-4 p-2 bg-red-100 border border-red-400 text-red-700 rounded">
              {errorMessage}
            </div>
          )}
          {!liveList || isLoading ? (
            <SpinnerLoader />
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 overflow-x-auto md:overflow-x-visible">
              {liveList.length === 0 ? (
                <EmptyList title="No hay transmisiones en vivo">
                  No hay transmisiones en vivo disponibles. Por favor, vuelve a
                  intentarlo más tarde.
                </EmptyList>
              ) : (
                liveList.map((liveStream) => (
                  <Link
                    to={`/live/${liveStream.slug}`}
                    key={liveStream.slug}
                    className="p-4 flex flex-col items-center hover:bg-gray-300"
                  >
                    <div className="w-full h-48 mb-2 rounded overflow-hidden border-4 bg-white border-white">
                      <img
                        src={
                          API_BASE_URL + liveStream.thumbnail ||
                          DEFAULT_VOD_THUMBNAIL
                        }
                        alt={liveStream.title}
                        className="w-full h-full object-contain"
                        onError={(e) => {
                          const target = e.target as HTMLImageElement;
                          target.src = DEFAULT_VOD_THUMBNAIL;
                        }}
                      />
                    </div>
                    <h2 className="text-lg font-semibold text-center">
                      {liveStream.title}
                    </h2>
                  </Link>
                ))
              )}
            </div>
          )}
        </div>
      </main>
    </div>
  );
}

export default withAuth(loggedInTemplate(LiveList));

import { API_BASE_URL, WEB_ROUTE_VODS_MANAGE } from "@constants";
import { Link } from "react-router-dom";
import { IVod } from "@hooks/useVod";
import SpinnerLoader from "@components/SpinnerLoader";
import { DEFAULT_VOD_THUMBNAIL } from "@/constants/images";

interface IVodManageItemProps {
  vod: IVod;
}

function SentVod(props: IVodManageItemProps) {
  const { vod } = props;

  return (
    <div className="p-4 flex flex-col items-center">
      <div className="w-full h-48 mb-2 rounded overflow-hidden border-4 bg-white border-white relative">
        <img
          src={API_BASE_URL + vod.thumbnail || DEFAULT_VOD_THUMBNAIL}
          alt={vod.title}
          className="w-full h-full object-contain blur-sm grayscale"
          onError={(e) => {
            const target = e.target as HTMLImageElement;
            target.src = DEFAULT_VOD_THUMBNAIL;
          }}
        />
        <div className="absolute inset-0 flex flex-col items-center justify-center">
          <div
            className="p-2 bg-white bg-opacity-50 backdrop-filter backdrop-blur-sm rounded-2xl shadow-lg mb-2"
            style={{
              boxShadow: "rgba(255, 255, 255, 0.65) 0px 0px 16px 8px",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8 text-gray-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <div
            className="text-md text-primary-dark font-semibold p-2 bg-white bg-opacity-50 backdrop-filter backdrop-blur-sm rounded-2xl shadow-lg mb-2"
            style={{
              boxShadow: "rgba(255, 255, 255, 0.65) 0px 0px 16px 8px",
            }}
          >
            En cola
          </div>
        </div>
      </div>
      <h2 className="text-lg font-semibold text-center">{vod.title}</h2>
    </div>
  );
}

function StartedVod(props: IVodManageItemProps) {
  const { vod } = props;

  return (
    <div className="p-4 flex flex-col items-center">
      <div className="w-full h-48 mb-2 rounded overflow-hidden border-4 bg-white border-white relative">
        <img
          src={API_BASE_URL + vod.thumbnail || DEFAULT_VOD_THUMBNAIL}
          alt={vod.title}
          className="w-full h-full object-contain blur-sm"
          onError={(e) => {
            const target = e.target as HTMLImageElement;
            target.src = DEFAULT_VOD_THUMBNAIL;
          }}
        />
        <div className="absolute inset-0 flex flex-col items-center justify-center">
          <SpinnerLoader
            className="p-2 bg-white bg-opacity-50 backdrop-filter backdrop-blur-sm rounded-2xl shadow-lg mb-2"
            style={{
              boxShadow: "rgba(255, 255, 255, 0.65) 0px 0px 16px 8px",
            }}
          />
          <div
            className="text-md text-primary-dark font-semibold p-2 bg-white bg-opacity-50 backdrop-filter backdrop-blur-sm rounded-2xl shadow-lg mb-2 flex items-center"
            style={{
              boxShadow: "rgba(255, 255, 255, 0.65) 0px 0px 16px 8px",
            }}
          >
            <span>Subiendo</span>
            <span className="inline-flex ml-1 w-6">
              <span className="animate-fade-in-out-1">.</span>
              <span className="animate-fade-in-out-2">.</span>
              <span className="animate-fade-in-out-3">.</span>
            </span>
          </div>
        </div>
      </div>
      <h2 className="text-lg font-semibold text-center">{vod.title}</h2>
    </div>
  );
}

function FinishedVod(props: IVodManageItemProps) {
  const { vod } = props;

  return (
    <Link
      to={`${WEB_ROUTE_VODS_MANAGE}/${vod.id}`}
      className="p-4 flex flex-col items-center hover:bg-gray-300"
    >
      <div className="w-full h-48 mb-2 rounded overflow-hidden border-4 bg-white border-white">
        <img
          src={API_BASE_URL + vod.thumbnail || DEFAULT_VOD_THUMBNAIL}
          alt={vod.title}
          className="w-full h-full object-contain"
          onError={(e) => {
            const target = e.target as HTMLImageElement;
            target.src = DEFAULT_VOD_THUMBNAIL;
          }}
        />
      </div>
      <div className="flex items-center justify-center">
        {!vod.visible && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 mr-2 text-gray-500"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z"
              clipRule="evenodd"
            />
            <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
          </svg>
        )}
        <h2 className="text-lg font-semibold text-center">{vod.title}</h2>
      </div>
    </Link>
  );
}

export default function VodManageItem(props: IVodManageItemProps) {
  const { vod } = props;

  switch (vod.status) {
    case "SENT":
      return <SentVod vod={vod} />;
    case "STARTED":
      return <StartedVod vod={vod} />;
    default:
    case "FINISHED":
      return <FinishedVod vod={vod} />;
  }
}

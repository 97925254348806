interface FileUploaderProps {
  name: string;
  accept: string;
  message: string;
  changeMessage?: string;
  file: File | null;
  setFile: (file: File | null) => void;
  onChange?: (file: File | null) => void;
}

const formatFileSize = (bytes: number): string => {
  const units = ["B", "KB", "MB", "GB"];
  let size = bytes;
  let unitIndex = 0;

  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex++;
  }

  return `${size.toFixed(1)} ${units[unitIndex]}`;
};

const FileUploader = ({
  name,
  accept,
  message,
  changeMessage,
  file,
  setFile,
  onChange,
}: FileUploaderProps) => {
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      if (onChange) onChange(e.target.files[0]);
    }
  };

  return (
    <>
      <div className="flex flex-row">
        <input
          id={name}
          name={name}
          type="file"
          onChange={handleFileChange}
          accept={accept}
          hidden
        />
        <label
          htmlFor={name}
          className="h-fit px-4 py-2 rounded bg-primary hover:bg-primary-light text-white cursor-pointer"
        >
          {file && changeMessage ? changeMessage : message}
        </label>
        {file && (
          <div className="flex flex-col ml-4 justify-center text-sm">
            <p>
              <b>Nombre:</b> {file.name}
            </p>
            <p>
              <b>Tamaño:</b> {formatFileSize(file.size)}
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default FileUploader;

interface IToggleProps {
  checked: boolean;
  checkedLabel?: string;
  uncheckedLabel?: string;
  onClick?: () => void;
}

export default function Toggle(props: IToggleProps) {
  const { checked, checkedLabel, uncheckedLabel, onClick } = props;

  return (
    <div className="flex items-center">
      <div className="relative inline-block w-14 mr-2 align-middle select-none transition duration-200 ease-in">
        <input type="checkbox" defaultChecked={checked} className="sr-only" />
        <div
          className={`block w-14 h-8 rounded-full cursor-pointer ${
            checked ? "bg-primary-light" : "bg-primary-x-dark"
          }`}
          onClick={onClick}
        >
          <div
            className={`absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition-transform duration-200 ease-in-out transform ${
              checked ? "translate-x-6" : ""
            }`}
          ></div>
        </div>
      </div>
      {(checked && checkedLabel) || (!checked && uncheckedLabel) ? (
        <span
          className={`ml-2 ${checked ? "text-primary" : "text-primary-x-dark"}`}
        >
          {checked ? checkedLabel : uncheckedLabel}
        </span>
      ) : null}
    </div>
  );
}

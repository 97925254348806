import loggedInTemplate from "@/components/HOC/templates/loggedInTemplate";
import { withAuth } from "@/components/HOC/withAuth";
import { WEB_ROUTE_STREAM_PROFILE } from "@/constants";
import { useAuthStore } from "@/hooks/useAuthStore";
import { useUserStore } from "@/hooks/useUserStore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function Profile() {
  const { myUser, getMyData, updatePassword } = useUserStore();
  const userStore = useUserStore();
  const authStore = useAuthStore();
  const navigate = useNavigate();
  const [email, setEmail] = useState(myUser?.email || "");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  useEffect(() => {
    if (authStore.isLiveAuthenticated) {
      navigate(WEB_ROUTE_STREAM_PROFILE);
    }
  }, [authStore.isLiveAuthenticated, navigate]);

  useEffect(() => {
    getMyData();
  }, [getMyData]);

  useEffect(() => {
    if (myUser?.email) {
      setEmail(myUser.email);
    }
  }, [myUser?.email]);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newPassword && !oldPassword) {
      setPasswordError("Old password is required when setting a new password");
      return;
    }
    if (newPassword || oldPassword) {
      try {
        await updatePassword(oldPassword, newPassword);
        setOldPassword("");
        setNewPassword("");
        setPasswordError("");
      } catch (error) {
        setPasswordError("Failed to update password");
      }
    }
  };

  function handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    const newEmail = e.target.value;
    setEmail(newEmail);
    if (validateEmail(newEmail)) {
      setEmailError("");
    } else {
      setEmailError("Invalid email format");
    }
  }

  return (
    <div className="max-w-md mx-auto mt-8">
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="email" className="block mb-2">
            Email
          </label>
          <input
            type="email"
            id="email"
            autoComplete="email"
            value={email}
            onChange={handleEmailChange}
            className="w-full px-3 py-2 border rounded"
          />
          {emailError && <p className="text-red-500 mt-1">{emailError}</p>}
        </div>
        <div className="mb-4">
          <label className="block mb-2">Reset Password</label>
          <div className="flex space-x-2">
            <input
              type="password"
              placeholder="Old Password"
              autoComplete="current-password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              className="w-1/2 px-3 py-2 border rounded"
            />
            <input
              type="password"
              placeholder="New Password"
              autoComplete="new-password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="w-1/2 px-3 py-2 border rounded"
            />
          </div>
          {passwordError && (
            <p className="text-red-500 mt-1">{passwordError}</p>
          )}
        </div>
        {userStore.successMessage && (
          <div className="mb-4 p-2 bg-green-100 border border-green-400 text-green-700 rounded">
            {userStore.successMessage}
          </div>
        )}
        {userStore.errorMessage && (
          <div className="mb-4 p-2 bg-red-100 border border-red-400 text-red-700 rounded">
            {userStore.errorMessage}
          </div>
        )}
        {userStore.isLoading ? (
          <div className="w-full flex justify-center py-2">
            <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-white"></div>
          </div>
        ) : (
          <button
            type="submit"
            className="w-full bg-primary-light text-white py-2 rounded"
          >
            Update Profile
          </button>
        )}
      </form>
    </div>
  );
}

export default withAuth(loggedInTemplate(Profile));

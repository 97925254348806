export interface CookieOptions {
  expires?: Date;
  path?: string;
  domain?: string;
  secure?: boolean;
  sameSite?: "Strict" | "Lax" | "None";
  maxAge?: number;
}

export function setCookie(
  name: string,
  value: string,
  options: CookieOptions = {}
): void {
  let cookieString = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

  if (options.expires) {
    cookieString += `; expires=${options.expires.toUTCString()}`;
  }
  if (options.path) {
    cookieString += `; path=${options.path}`;
  }
  if (options.domain) {
    cookieString += `; domain=${options.domain}`;
  }
  if (options.secure) {
    cookieString += "; secure";
  }
  if (options.sameSite) {
    cookieString += `; samesite=${options.sameSite}`;
  }
  if (options.maxAge) {
    cookieString += `; max-age=${options.maxAge}`;
  }

  document.cookie = cookieString;
}

export function getCookie(name: string): string | null {
  const cookies = document.cookie.split(";");
  for (let cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split("=").map((c) => c.trim());
    if (cookieName === name) {
      return decodeURIComponent(cookieValue);
    }
  }
  return null;
}

export function deleteCookie(name: string): void {
  setCookie(name, "", { expires: new Date(0) });
}

export function getAllCookies(): Record<string, string> {
  const cookieObj: Record<string, string> = {};
  const cookies = document.cookie.split(";");
  for (let cookie of cookies) {
    const [name, value] = cookie.split("=").map((c) => c.trim());
    if (name) {
      cookieObj[name] = decodeURIComponent(value);
    }
  }
  return cookieObj;
}

export default function Landing2() {
  return (
    <section className="min-h-screen flex flex-col items-center justify-center bg-[#141414] text-center p-4 md:p-8 uppercase">
      <h2 className="text-4xl md:text-7xl font-anton text-secondary mb-4 md:mb-6 italic">
        QUÉ OFRECEMOS
      </h2>
      <p className="text-base md:text-lg max-w-2xl text-white px-2">
        Nos comprometemos a brindarte la mejor experiencia de visionado.
      </p>
      <div className="flex flex-col md:flex-row justify-center gap-4 md:gap-8 mt-4 md:mt-8 w-full max-w-7xl">
        <div className="relative h-[40vh] md:h-[60vh] md:flex-1">
          <div className="w-full h-full">
            <img
              src="/img/landing/SEGUNDA PANTALLA 1.jpg"
              alt="Vista cenital de un campo de fútbol"
              className="w-full h-full object-cover shadow-lg"
            />
            <p className="absolute top-[66%] mx-4 md:mx-20 left-0 right-0 font-medium text-white font-anton py-2 italic text-xl md:text-3xl uppercase outlined-text-1">
              EL DEPORTE BASE DE LA REGIÓN DE MURCIA
            </p>
          </div>
        </div>
        <div className="sm:block relative h-[40vh] md:h-[60vh] md:flex-1">
          <div className="w-full h-full">
            <img
              src="/img/landing/SEGUNDA PANTALLA 2.jpg"
              alt="Móvil grabando un partido de fútbol"
              className="w-full h-full object-cover shadow-lg"
            />
            <p className="absolute top-[66%] mx-20 left-0 right-0 font-medium text-white font-anton py-2 italic text-3xl uppercase outlined-text-1">
              EN DIRECTO Y BAJO DEMANDA
            </p>
          </div>
        </div>
        <div className="relative h-[40vh] md:h-[60vh] md:flex-1 overflow-hidden">
          <div className="w-full h-full">
            <img
              src="/img/landing/SEGUNDA PANTALLA 3.png"
              alt="Persona accediendo a la app en un portátil"
              className="w-full h-full object-cover object-[center_75%] md:object-bottom"
            />
            <p className="absolute top-[66%] mx-4 md:mx-20 left-0 right-0 font-medium text-white font-anton py-2 italic text-xl md:text-3xl uppercase outlined-text-1">
              GRATIS PARA CLIENTES DE LIBERA
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

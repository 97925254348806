import SideBar from "@components/SideBar";
import HeaderBar from "@/components/HeaderBar";
import { useSidebar } from "@/contexts/SidebarContext";

function loggedInTemplate<P extends object>(Component: React.ComponentType<P>) {
  return (props: P) => {
    const { sidebarOpen, setSidebarOpen } = useSidebar();

    return (
      <div className="flex h-screen bg-gray-200">
        <SideBar isOpen={sidebarOpen} setIsOpen={setSidebarOpen} />
        <div className="flex-1 flex flex-col overflow-hidden">
          <HeaderBar
            isSidebarOpen={sidebarOpen}
            setIsSidebarOpen={setSidebarOpen}
          />
          <Component {...(props as P)} />
        </div>
      </div>
    );
  };
}

export default loggedInTemplate;

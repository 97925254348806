import { REFRESH_PENDING_VODS_INTERVAL } from "@/constants/feedback";
import { API_ROUTE_VODS_MANAGE } from "@constants";
import { useRequest } from "@hooks/useRequest";
import { useState, useEffect } from "react";

interface IVodVersion {
  id: string;
  dateCreated: string;
  resolution: string;
  bitrate?: string;
  size?: string;
  visible: boolean;
}

interface IVodTranscodeHistory {
  id: string;
  dateCreated: string;
  status: string;
  message?: string;
}

export interface IManageVod {
  id: string;
  dateCreated: string;
  title: string;
  summary: string;
  thumbnail: string;
  size: string;
  visible: boolean;
  status: "SENT" | "STARTED" | "FINISHED";
  versions?: IVodVersion[];
  transcodeHistory?: IVodTranscodeHistory[];
}

export interface IManageVodsListResponse {
  vodsList: IManageVod[] | null;
  isLoading: boolean;
  errorMessage: string;
}

function hasNotFinishedVods(vods: IManageVod[] | null) {
  return (
    vods && vods.length > 0 && vods.some((vod) => vod.status !== "FINISHED")
  );
}

function useRefresPendingVods(
  vods: IManageVod[] | null,
  refetchtInBackground: () => void
) {
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (hasNotFinishedVods(vods) && !intervalId) {
      const intervalId = setInterval(() => {
        refetchtInBackground();
      }, REFRESH_PENDING_VODS_INTERVAL);
      setIntervalId(intervalId);
    } else if (!hasNotFinishedVods(vods) && intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
        setIntervalId(null);
      }
    };
  }, [vods, intervalId, refetchtInBackground]);
}

export function useManageVodsList() {
  const { data, isLoading, errorMessage, refetchtInBackground } = useRequest<
    IManageVod[]
  >({
    url: API_ROUTE_VODS_MANAGE,
    method: "GET",
  });

  useRefresPendingVods(data?.vods, refetchtInBackground);

  return { vodsList: data?.vods, isLoading, errorMessage };
}

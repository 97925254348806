import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import config from "@config";
import {
  API_BASE_URL,
  API_ROUTE_VODS_MANAGE,
  WEB_ROUTE_VODS_MANAGE,
} from "@constants";
import { useAuthStore } from "@hooks/useAuthStore";
import loggedInTemplate from "@components/HOC/templates/loggedInTemplate";
import { withLiveAuth } from "@components/HOC/withAuth";
import FileUploader from "@components/FileUploader";
import ActionButton from "@/components/ActionButton";

function VodCreate() {
  const authStore = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `${config.public.appName} - Subir VOD`;
  }, []);

  const [title, setTitle] = useState("");
  const [summary, setSummary] = useState("");
  const [vodFile, setVodFile] = useState<File | null>(null);
  const [vodThumbFile, setVodThumbFile] = useState<File | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!vodFile) {
      return;
    }
    console.log("Uploading file...");

    const formData = new FormData();
    formData.append("vodFile", vodFile);
    formData.append("title", title);
    formData.append("summary", summary);
    if (vodThumbFile) {
      formData.append("vodThumbFile", vodThumbFile);
    }

    try {
      setIsLoading(true);
      const result = await fetch(API_BASE_URL + API_ROUTE_VODS_MANAGE, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${authStore.token}`,
        },
      });

      const response = await result.json();
      setIsLoading(false);
      if (response.statusCode >= 200 && response.statusCode < 400) {
        navigate(WEB_ROUTE_VODS_MANAGE);
      } else {
        setErrorMessage(response.message);
      }
    } catch (error) {
      console.error(error);
      setErrorMessage("Error al subir el video");
      setIsLoading(false);
    }
  };

  return (
    <div className="relative w-full h-full mx-auto mt-8 mb-8 md:mb-0 overflow-y-auto max-h-screen p-4">
      <form onSubmit={handleSubmit} className="max-w-2xl mx-auto">
        {/* TITLE */}
        <div className="mb-8 flex items-center">
          <label htmlFor="title" className="mr-8">
            Título{title ? "*" : <span className="text-red-500">*</span>}
          </label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-3/4 px-3 py-2 border rounded"
          />
        </div>

        {/* FILE */}
        <div className="mb-8 flex items-baseline">
          <label htmlFor="vodFile" className="mr-8">
            Archivo{vodFile ? "*" : <span className="text-red-500">*</span>}
          </label>
          <div className="w-3/4">
            <FileUploader
              name="vodFile"
              accept="video/*"
              file={vodFile}
              setFile={setVodFile}
              message="Seleccionar video"
              changeMessage="Cambiar video"
            />
          </div>
        </div>

        {/* THUMBNAIL */}
        <div className="mb-8 flex items-baseline">
          <label htmlFor="vodThumbFile" className="mr-8">
            Miniatura
          </label>
          <div className="w-3/4">
            <FileUploader
              name="vodThumbFile"
              accept="image/*"
              file={vodThumbFile}
              setFile={setVodThumbFile}
              message="Seleccionar imagen"
              changeMessage="Cambiar imagen"
            />
          </div>
        </div>

        {/* SUMMARY */}
        <div className="mb-8">
          <label htmlFor="summary" className="block mb-2">
            Descripción
          </label>
          <textarea
            id="summary"
            value={summary}
            onChange={(e) => setSummary(e.target.value)}
            className="w-full px-3 py-2 border rounded"
          />
        </div>

        {isLoading && (
          <div className="mb-4 p-2 bg-secondary-x-light border border-secondary-dark text-secondary-dark rounded">
            Subiendo Video, por favor, no cierres esta ventana
            <span className="inline-flex ml-1 w-6">
              <span className="animate-fade-in-out-1">.</span>
              <span className="animate-fade-in-out-2">.</span>
              <span className="animate-fade-in-out-3">.</span>
            </span>
          </div>
        )}

        <ActionButton
          isLoading={isLoading}
          errorMessage={errorMessage}
          mandatoryFields={[title, vodFile]}
        >
          Crear VOD
        </ActionButton>
      </form>
    </div>
  );
}

export default withLiveAuth(loggedInTemplate(VodCreate));

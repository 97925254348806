export default function Landing3() {
  return (
    <section
      className="md:min-h-screen px-4 md:px-20 py-12 md:py-0 flex flex-col md:flex-row items-center justify-center md:justify-between bg-cover bg-center"
      style={{
        backgroundImage: "url('/img/landing/TERCERA PANTALLA - FONDO.jpg')",
      }}
    >
      <div className="max-w-xl mb-8 md:mb-0 flex-1 flex flex-col">
        <h2 className="text-4xl md:text-7xl text-center font-anton text-secondary italic outlined-text-2 uppercase">
          DESDE TU ORDENADOR, TU TV O TU SMARTPHONE
        </h2>
        <p className="text-xl md:text-2xl font-bold text-white text-left md:text-left mt-4 outlined-text-2 md:outlined-text-none bg-black bg-opacity-70 p-2 md:p-0 md:bg-transparent">
          APPS EN DESARROLLO. Compatible con Chromecast. Ya puedes disfrutar de
          Baseados en tu Smart TV.
        </p>
      </div>

      <div className="flex space-x-4 md:space-x-16 mt-auto md:mt-0">
        <div className="flex flex-col items-center">
          <img
            src="/img/landing/TERCERA PANTALLA 1.png"
            alt="iPhone App"
            className="h-48 md:h-[28rem] object-contain mb-4 md:mb-8"
          />
          <img
            src="/img/landing/badge-example-alternate_2x.png"
            alt="Descarga en App Store"
            className="h-8 md:h-12 cursor-pointer hover:opacity-80"
          />
        </div>

        <div className="flex flex-col items-center">
          <img
            src="/img/landing/TERCERA PANTALLA 2.png"
            alt="Android App"
            className="h-48 md:h-[28rem] object-contain mb-4 md:mb-8"
          />
          <img
            src="/img/landing/1000_F_386668700_EmK1sLisBQmWe6pLv4o67UYQLXULFUAJ.png"
            alt="Descarga en Google Play"
            className="h-8 md:h-12 cursor-pointer hover:opacity-80"
          />
        </div>
      </div>
    </section>
  );
}

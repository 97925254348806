import { HTTP_409_CONFLICT_ERROR_IN_VIDEO_CHUNK_REQUEST } from "@/constants/network";
import React, { useEffect, useRef } from "react";
import "../assets/videojs-chromecast/silvermine-videojs-chromecast.css";

interface VideoPlayerProps {
  src: string;
  onReady?: (player: any) => void;
  setErrorMessage?: (errorMessage: string) => void;
  live?: boolean;
}

const videojs = window.videojs as any;

function getOptions(src: string, live?: boolean) {
  return {
    autoplay: "play",
    controls: true,
    responsive: true,
    fluid: true,
    liveui: live,
    techOrder: ["chromecast", "html5"],
    sources: [
      {
        src,
        type: "application/x-mpegURL",
      },
    ],
    plugins: {
      chromecast: {
        addButtonToControlBar: true,
        forceVisibility: true,
      },
    },
  };
}

function showMessageOnError(
  player: any,
  setErrorMessage?: (errorMessage: string) => void
): void {
  const tech = player.tech() as any;
  tech?.on("retryplaylist", () => {
    if (tech.vhs?.xhr) {
      tech.vhs.xhr.onResponse((response: any) => {
        if (response.status === 409) {
          player.pause();
          setErrorMessage?.(HTTP_409_CONFLICT_ERROR_IN_VIDEO_CHUNK_REQUEST);
        }
      });
    }
  });
}

const VideoPlayer: React.FC<VideoPlayerProps> = (props) => {
  const { src, onReady, setErrorMessage, live } = props;
  const videoRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<any | null>(null);

  useEffect(() => {
    const options = getOptions(src, live);

    if (!playerRef.current) {
      const videoElement = document.createElement("video-js");

      videoElement.classList.add("vjs-big-play-centered");
      videoRef.current?.appendChild(videoElement);

      const player = (playerRef.current = videojs(videoElement, options, () => {
        showMessageOnError(player, setErrorMessage);
        onReady?.(player);
      }));
    } else {
      playerRef.current.tech({
        name: "html5",
      });
      playerRef.current.src(options.sources);
    }
  }, [src, onReady, setErrorMessage, live]);

  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div className="max-w-full h-[70vh] mx-auto relative">
      <div data-vjs-player className="absolute inset-0">
        <div
          ref={videoRef}
          className={`video-js ${
            live ? "vjs-live vjs-liveui" : ""
          } w-full h-full object-contain [&>video-js.video-js.vjs-fluid]:absolute [&>video-js.video-js.vjs-fluid]:inset-0 [&>video-js.video-js.vjs-fluid]:h-auto [&>video-js.video-js.vjs-fluid]:p-0`}
        />
      </div>
    </div>
  );
};

export default VideoPlayer;

import React, { useEffect } from "react";

import config from "@config";
import Landing1 from "@components/Landing/Landing1";
import Landing2 from "@components/Landing/Landing2";
import Landing3 from "@components/Landing/Landing3";
import Landing4 from "@components/Landing/Landing4";
import Landing5 from "@components/Landing/Landing5";

const LandingPage: React.FC = () => {
  useEffect(() => {
    document.title = config.public.appName;
  }, []);

  return (
    <div className="bg-white text-gray-900">
      {/* Section 1: DEPORTE BASE A TU ALCANCE */}
      <Landing1 />

      {/* Section 2: QUÉ OFRECEMOS */}
      <Landing2 />

      {/* Section 3: DESDE TU ORDENADOR, TU TV O TU SMARTPHONE */}
      <Landing3 />

      {/* Section 4: EQUIPOS */}
      <Landing4 />

      {/* Section 5: ¡APOYA EL DEPORTE BASE! */}
      <Landing5 />
    </div>
  );
};

export default LandingPage;

export default function Landing5() {
  return (
    <section className="h-screen flex flex-col items-center justify-center">
      <div
        className="flex-1 flex flex-col items-center justify-around bg-cover bg-bottom text-center w-full px-4"
        style={{
          backgroundImage: "url('/img/landing/QUINTA PANTALLA - FONDO.jpg')",
        }}
      >
        <h2 className="text-3xl md:text-7xl font-anton text-white mt-4 mb-6 italic uppercase">
          ¡APOYA EL DEPORTE BASE!
        </h2>
        <div className="flex flex-col md:flex-row gap-6 md:gap-24">
          <a
            href="#"
            className="w-full md:w-64 px-6 md:px-10 py-4 bg-secondary text-primary hover:bg-secondary-x-light uppercase font-bold text-lg md:text-xl transition-colors duration-200"
          >
            ÚNETE HOY
          </a>
          <a
            href="/login"
            className="w-full md:w-64 px-6 md:px-10 py-4 bg-primary text-secondary hover:bg-primary-dark uppercase font-bold text-lg md:text-xl transition-colors duration-200"
          >
            CLIENTES LIBERA
          </a>
        </div>
        <img
          src="/img/Imagotipo Horizontal - N.svg"
          alt="Logo Baseados"
          className="w-64 md:w-[32rem] mt-16 mb-8 md:mb-16"
        />
      </div>
      <footer className="w-full bg-secondary text-black py-4 md:py-8 px-4">
        <div className="flex flex-col md:flex-row md:justify-around gap-8 md:gap-0 mb-8 text-center md:text-left">
          <div>
            <h4 className="font-anton text-xl md:text-3xl mb-2 uppercase italic">APPS</h4>
            <p className="text-md md:text-xl">En desarrollo</p>
          </div>
          <div>
            <h4 className="font-anton text-xl md:text-3xl mb-2 uppercase italic">
              CONTACTO
            </h4>
            <a
              href="mailto:hola@baseados.tv"
              className="text-md md:text-xl hover:text-white"
            >
              hola@baseados.tv
            </a>
          </div>
          <div>
            <h4 className="font-anton text-xl md:text-3xl mb-2 uppercase italic">
              REDES SOCIALES
            </h4>
            <a
              href="https://x.com/liberatelecom"
              className="text-md md:text-xl hover:text-white"
            >
              @liberatelecom
            </a>
          </div>
        </div>
        <div className="text-center">
          <div className="mb-2">
            <a href="#" className="hover:text-white">
              Aviso legal
            </a>
            <span className="mx-2">·</span>
            <a href="#" className="hover:text-white">
              Política de cookies
            </a>
            <span className="mx-2">·</span>
            <a href="#" className="hover:text-white">
              Política de privacidad
            </a>
          </div>
          <p className="m-2">
            Baseados - All Rights Reserved - 2024 - Powered by Liberatelecom
          </p>
        </div>
      </footer>
    </section>
  );
}

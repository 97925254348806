import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthStore } from "@hooks/useAuthStore";

export function withAuth<P extends object>(
  WrappedComponent: React.ComponentType<P>
) {
  const WithAuth: React.FC<P> = (props) => {
    const { isAuthenticated } = useAuthStore();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      if (!isAuthenticated) {
        navigate("/login", {
          replace: true,
          state: { referrer: location.pathname },
        });
      }
    }, [isAuthenticated, navigate, location]);

    if (!isAuthenticated) {
      return null; // or a loading spinner
    }

    return <WrappedComponent {...props} />;
  };

  return WithAuth;
}

export function withLiveAuth<P extends object>(
  WrappedComponent: React.ComponentType<P>
) {
  const WithLiveAuth: React.FC<P> = (props) => {
    const { isLiveAuthenticated } = useAuthStore();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      if (!isLiveAuthenticated) {
        navigate("/login", {
          replace: true,
          state: { referrer: location.pathname },
        });
      }
    }, [isLiveAuthenticated, navigate, location]);

    if (!isLiveAuthenticated) {
      return null; // or a loading spinner
    }

    return <WrappedComponent {...props} />;
  };

  return WithLiveAuth;
}

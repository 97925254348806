import { ReactNode } from "react";

interface IEmptyListProps {
  children: ReactNode;
  title: string;
}

const EmptyList = ({ children, title }: IEmptyListProps) => (
  <div className="col-span-full flex flex-col items-center justify-center p-8 bg-white rounded-lg shadow">
    <svg
      className="w-24 h-24 text-gray-400 mb-4"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
      />
    </svg>
    <h3 className="text-xl font-semibold text-gray-900 mb-2">{title}</h3>
    <p className="text-gray-600 text-center">{children}</p>
  </div>
);

export default EmptyList;

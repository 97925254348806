import { useState, useEffect } from "react";

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const hasAccepted = localStorage.getItem("cookieConsent");
    if (!hasAccepted) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "true");
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white shadow-lg z-[9999]">
      <div className="flex flex-col sm:flex-row items-center max-w-7xl mx-auto px-4 sm:px-6">
        <div className="max-h-[80px] sm:max-h-none overflow-y-auto py-3 sm:py-4">
          <p className="mb-4 sm:mb-2 sm:mr-16 text-sm text-center sm:text-left pr-2">
            Este sitio web utiliza cookies esenciales para garantizar su
            funcionamiento adecuado y cookies de seguimiento para comprender cómo
            interactúa con él. Las siguientes cookies se utilizan: cookie de
            autenticación (necesaria): Usada para mantenerte conectado; cookie de
            autenticación de administrador (necesaria): Usada para acceder al
            panel de administración
          </p>
        </div>
        <div className="py-2 bg-white border-t sm:border-t-0">
          <button
            onClick={handleAccept}
            className="bg-primary text-white border-none py-2 px-8 sm:px-4 rounded cursor-pointer hover:bg-primary-dark whitespace-nowrap"
          >
            Entendido
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieBanner;

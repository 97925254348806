interface ModalProps {
  children: React.ReactNode;
  isOpen: boolean;
  setIsOpen?: (isOpen: boolean) => void;
}

function Modal(props: ModalProps) {
  const { children, isOpen, setIsOpen } = props;

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
      style={{ display: isOpen ? "flex" : "none" }}
      onClick={() => setIsOpen?.(false)}
    >
      <div 
        className="p-6 bg-white rounded shadow-lg z-20"
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
}

export default Modal;

import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  WEB_ROUTE_VODS,
  WEB_ROUTE_LIVE_EVENTS,
  WEB_ROUTE_STREAM_PROFILE,
  WEB_ROUTE_PROFILE,
  WEB_ROUTE_VOD_CREATE,
  WEB_ROUTE_VODS_MANAGE,
  WEB_ROUTE_LANDING,
} from "@constants";
import { IMAGOTYPE_PRIMARY_NEGATIVE } from "@constants/images";
import { useAuthStore } from "@hooks/useAuthStore";
import { useIsAnyoneLive } from "@hooks/useLiveList";

interface SideBarProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const SideBar: React.FC<SideBarProps> = ({ isOpen, setIsOpen }) => {
  const location = useLocation();
  const authStore = useAuthStore();

  useEffect(() => {
    setIsOpen(false);
  }, [location, setIsOpen]);

  const closeSidebar = () => setIsOpen(false);

  const isAnyoneLive = useIsAnyoneLive();

  const activeClass = "bg-primary-light bg-opacity-25 text-white border-white";
  const inactiveClass =
    "border-primary-x-dark text-white hover:bg-primary-light hover:bg-opacity-25 hover:text-white";
  const disabledClass = "border-primary-x-dark text-white";

  return (
    <div className="flex z-10">
      {/* Backdrop */}
      <div
        className={`fixed inset-0 z-20 transition-opacity bg-black opacity-50 lg:hidden ${
          isOpen ? "block" : "hidden"
        }`}
        onClick={closeSidebar}
      />

      <div
        className={`fixed inset-y-0 left-0 z-30 w-64 overflow-y-auto transition duration-300 transform bg-primary-dark lg:translate-x-0 lg:static lg:inset-0 ${
          isOpen ? "translate-x-0 ease-out" : "-translate-x-full ease-in"
        }`}
      >
        <Link to={WEB_ROUTE_LANDING} onClick={closeSidebar}>
          <div className="flex items-center justify-center mt-4">
            <div className="flex items-center">
              <img
                className="h-8"
                src={IMAGOTYPE_PRIMARY_NEGATIVE}
                alt="Logo Baseados"
              />
            </div>
          </div>
        </Link>

        <div className="flex items-center mt-4">
          <div className="flex-1 border border-t-0 border-primary-x-dark"></div>
        </div>

        <nav>
          {/* LIVE EVENTS */}
          <div>
            <Link
              className={`flex items-center px-6 py-2 duration-200 border-l-4 text-sm ${
                location.pathname === WEB_ROUTE_LIVE_EVENTS
                  ? activeClass
                  : inactiveClass
              }`}
              to={WEB_ROUTE_LIVE_EVENTS}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-5 h-5"
              >
                <path d="M4.5 4.5a3 3 0 0 0-3 3v9a3 3 0 0 0 3 3h8.25a3 3 0 0 0 3-3v-9a3 3 0 0 0-3-3H4.5ZM19.94 18.75l-2.69-2.69V7.94l2.69-2.69c.944-.945 2.56-.276 2.56 1.06v11.38c0 1.336-1.616 2.005-2.56 1.06Z" />
              </svg>
              <div className="flex items-center justify-between w-full">
                <span className="mx-4">En Directo</span>
                {isAnyoneLive && (
                  <span className="inline-flex items-center bg-red-700 text-white text-xs font-medium px-2.5 py-0.5 rounded-full">
                    <span className="w-2 h-2 me-1 bg-red-300 rounded-full"></span>
                    LIVE
                  </span>
                )}
              </div>
            </Link>
          </div>

          {/* VODS */}
          <Link
            className={`flex items-center px-6 py-2 duration-200 border-l-4 text-sm cursor-pointer ${
              location.pathname === WEB_ROUTE_VODS ? activeClass : inactiveClass
            }`}
            to={WEB_ROUTE_VODS}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-5 h-5"
            >
              <path d="M19.5 6h-15v9h15V6Z" />
              <path
                fillRule="evenodd"
                d="M3.375 3C2.339 3 1.5 3.84 1.5 4.875v11.25C1.5 17.16 2.34 18 3.375 18H9.75v1.5H6A.75.75 0 0 0 6 21h12a.75.75 0 0 0 0-1.5h-3.75V18h6.375c1.035 0 1.875-.84 1.875-1.875V4.875C22.5 3.839 21.66 3 20.625 3H3.375Zm0 13.5h17.25a.375.375 0 0 0 .375-.375V4.875a.375.375 0 0 0-.375-.375H3.375A.375.375 0 0 0 3 4.875v11.25c0 .207.168.375.375.375Z"
                clipRule="evenodd"
              />
            </svg>
            <div className="flex items-center justify-between w-full">
              <span className="mx-4">Videos</span>
            </div>
          </Link>

          {/* PPV */}
          <div
            className={`flex px-6 py-2 duration-200 border-l-4 text-sm ${disabledClass}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-5 h-5"
            >
              <path d="M4.5 3.75a3 3 0 0 0-3 3v.75h21v-.75a3 3 0 0 0-3-3h-15Z" />
              <path
                fillRule="evenodd"
                d="M22.5 9.75h-21v7.5a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3v-7.5Zm-18 3.75a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 0 1.5h-6a.75.75 0 0 1-.75-.75Zm.75 2.25a.75.75 0 0 0 0 1.5h3a.75.75 0 0 0 0-1.5h-3Z"
                clipRule="evenodd"
              />
            </svg>
            <div className="flex items-center justify-between w-full">
              <span className="mx-4">PPV</span>
              <span className="inline-flex items-center bg-amber-700 text-amber-300 text-xs font-medium px-2.5 py-0.5 rounded-full">
                <span className="w-2 h-2 me-1 bg-amber-300 rounded-full"></span>
                SOON
              </span>
            </div>
          </div>

          {/* PROFILE */}
          {/* {authStore.isAuthenticated && !authStore.isLiveAuthenticated && (
            <div>
              <Link
                className={`flex px-6 py-2 duration-200 border-l-4 text-sm cursor-pointer ${
                  location.pathname === WEB_ROUTE_PROFILE
                    ? activeClass
                    : inactiveClass
                }`}
                to={WEB_ROUTE_PROFILE}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
                    clipRule="evenodd"
                  />
                </svg>
                <div className="flex items-center justify-between w-full">
                  <span className="mx-4">Perfil</span>
                </div>
              </Link>
            </div>
          )} */}

          {/* Admin Section */}
          {authStore.isLiveAuthenticated && (
            <div className="px-6 py-2 text-xs font-semibold text-primary-light uppercase">
              Admin
            </div>
          )}

          {/* STREAM PROFILE */}
          {authStore.isLiveAuthenticated && (
            <div>
              <Link
                className={`flex px-6 py-2 duration-200 border-l-4 text-sm cursor-pointer ${
                  location.pathname === WEB_ROUTE_STREAM_PROFILE
                    ? activeClass
                    : inactiveClass
                }`}
                to={WEB_ROUTE_STREAM_PROFILE}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
                    clipRule="evenodd"
                  />
                </svg>
                <div className="flex items-center justify-between w-full">
                  <span className="mx-4">Perfil del Directo</span>
                </div>
              </Link>
            </div>
          )}

          {/* VOD CREATE */}
          {authStore.isLiveAuthenticated && (
            <div>
              <Link
                className={`flex px-6 py-2 duration-200 border-l-4 text-sm cursor-pointer ${
                  location.pathname === WEB_ROUTE_VOD_CREATE
                    ? activeClass
                    : inactiveClass
                }`}
                to={WEB_ROUTE_VOD_CREATE}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M11.47 2.47a.75.75 0 011.06 0l4.5 4.5a.75.75 0 01-1.06 1.06l-3.22-3.22V16.5a.75.75 0 01-1.5 0V4.81L8.03 8.03a.75.75 0 01-1.06-1.06l4.5-4.5zM3 15.75a.75.75 0 01.75.75v2.25a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5V16.5a.75.75 0 011.5 0v2.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V16.5a.75.75 0 01.75-.75z"
                    clipRule="evenodd"
                  />
                </svg>
                <div className="flex items-center justify-between w-full">
                  <span className="mx-4">Subir Video</span>
                </div>
              </Link>
            </div>
          )}

          {/* VOD MANAGE */}
          {authStore.isLiveAuthenticated && (
            <div>
              <Link
                className={`flex px-6 py-2 duration-200 border-l-4 text-sm cursor-pointer ${
                  location.pathname === WEB_ROUTE_VODS_MANAGE
                    ? activeClass
                    : inactiveClass
                }`}
                to={WEB_ROUTE_VODS_MANAGE}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M2 6a2 2 0 012-2h16a2 2 0 012 2v12a2 2 0 01-2 2H4a2 2 0 01-2-2V6zm3.293 1.293a1 1 0 011.414 0L8 8.586l1.293-1.293a1 1 0 111.414 1.414L9.414 10l1.293 1.293a1 1 0 01-1.414 1.414L8 11.414l-1.293 1.293a1 1 0 01-1.414-1.414L6.586 10 5.293 8.707a1 1 0 010-1.414zM14 9a1 1 0 100 2h4a1 1 0 100-2h-4zm0 4a1 1 0 100 2h4a1 1 0 100-2h-4z"
                    clipRule="evenodd"
                  />
                </svg>
                <div className="flex items-center justify-between w-full">
                  <span className="mx-4">Gestionar Videos</span>
                </div>
              </Link>
            </div>
          )}

          {/* LOGOUT */}
          <div
            onClick={() => {
              closeSidebar();
              authStore.logout();
            }}
            className={`flex items-center px-6 py-2 duration-200 border-l-4 text-sm cursor-pointer ${inactiveClass}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="w-5 h-5"
            >
              <path
                fillRule="evenodd"
                d="M7.5 3.75A1.5 1.5 0 0 0 6 5.25v13.5a1.5 1.5 0 0 0 1.5 1.5h6a1.5 1.5 0 0 0 1.5-1.5V15a.75.75 0 0 1 1.5 0v3.75a3 3 0 0 1-3 3h-6a3 3 0 0 1-3-3V5.25a3 3 0 0 1 3-3h6a3 3 0 0 1 3 3V9A.75.75 0 0 1 15 9V5.25a1.5 1.5 0 0 0-1.5-1.5h-6Zm10.72 4.72a.75.75 0 0 1 1.06 0l3 3a.75.75 0 0 1 0 1.06l-3 3a.75.75 0 1 1-1.06-1.06l1.72-1.72H9a.75.75 0 0 1 0-1.5h10.94l-1.72-1.72a.75.75 0 0 1 0-1.06Z"
                clipRule="evenodd"
              />
            </svg>
            <div className="flex items-center justify-between w-full">
              <span className="mx-4">Log out</span>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default SideBar;

import SpinnerLoader from "./SpinnerLoader";

interface ActionButtonProps {
  children: string | React.ReactNode;
  isLoading?: boolean;
  successMessage?: string;
  errorMessage?: string;
  enabled?: boolean;
  mandatoryFields?: any[];
}

export default function ActionButton(props: ActionButtonProps) {
  const {
    children,
    isLoading,
    successMessage,
    errorMessage,
    enabled = true,
    mandatoryFields,
  } = props;

  const hasMandatoryFields =
    !mandatoryFields ||
    !mandatoryFields.length ||
    mandatoryFields?.every((field) => !!field);

  const isButtonDisabled = !enabled || !hasMandatoryFields;

  return (
    <div className="md:relative md:bg-transparent fixed bottom-0 left-0 right-0 p-4 bg-white border-t">
      {!hasMandatoryFields && (
        <span className="text-red-500 text-xs md:text-sm relative md:absolute md:top-[-1em]">
          Debes completar todos los campos marcados con *
        </span>
      )}
      {isLoading ? (
        <SpinnerLoader />
      ) : successMessage ? (
        <div className="mb-4 p-2 bg-green-100 border border-green-400 text-green-700 rounded">
          {successMessage}
        </div>
      ) : (
        <>
          {errorMessage && (
            <div className="mb-4 p-2 bg-red-100 border border-red-400 text-red-700 rounded">
              {errorMessage}
            </div>
          )}
          <div className="flex justify-center mt-2">
            <button
              type="submit"
              className={`max-w-sm w-full py-2 rounded ${
                isButtonDisabled
                  ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                  : "bg-primary hover:bg-primary-light text-white cursor-pointer"
              }`}
              disabled={isButtonDisabled}
            >
              {children}
            </button>
          </div>
        </>
      )}
    </div>
  );
}

import React from "react";

interface SpinnerLoaderProps {
  className?: string;
  style?: React.CSSProperties;
}

const SpinnerLoader: React.FC<SpinnerLoaderProps> = (props) => {
  const { className = "", style } = props;

  return (
    <div
      className={`${className} flex justify-center items-center`}
      style={style}
    >
      <span className="w-12 h-12 border-4 border-secondary border-b-primary rounded-full inline-block box-border animate-spin"></span>
    </div>
  );
};

export default SpinnerLoader;

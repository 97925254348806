import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  API_BASE_URL,
  API_ROUTE_VODS_MANAGE,
  WEB_ROUTE_VODS_MANAGE,
} from "@constants";
import { useAuthStore } from "@hooks/useAuthStore";
import { useRequest } from "@hooks/useRequest";

export interface IManageVodVersion {
  id: string;
  dateCreated: string;
  resolution: string;
  bitrate?: string;
  size?: string;
  visible: boolean;
}

export interface IManageVodTranscodeHistory {
  id: string;
  dateCreated: string;
  status: string;
  message?: string;
}

export interface IManageVod {
  id: string;
  dateCreated: string;
  title: string;
  summary: string;
  thumbnail: string;
  size: string;
  visible: boolean;
  versions?: IManageVodVersion[];
  transcodeHistory?: IManageVodTranscodeHistory[];
}

export interface IManageVodResponse {
  vod: IManageVod | null;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  errorMessage: string;
  setErrorMessage: (errorMessage: string) => void;
  refetchtInBackground: () => void;
  deleteVod: () => Promise<void>;
}

function useManageVod(id: string): IManageVodResponse {
  const authStore = useAuthStore();
  const navigate = useNavigate();

  const {
    data,
    isLoading,
    setIsLoading,
    errorMessage,
    setErrorMessage,
    refetchtInBackground,
  } = useRequest<IManageVod>({
    url: `${API_ROUTE_VODS_MANAGE}/${id}`,
    method: "GET",
  });

  const deleteVod = useCallback(async () => {
    setIsLoading(true);
    const response = await fetch(
      `${API_BASE_URL}${API_ROUTE_VODS_MANAGE}/${id}`,
      {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${authStore.token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (response.ok) {
      setIsLoading(false);
      navigate(WEB_ROUTE_VODS_MANAGE);
    } else {
      setIsLoading(false);
      throw new Error("Error al eliminar el VOD");
    }
  }, [authStore.token, id, navigate, setIsLoading]);

  return {
    vod: data?.vod,
    isLoading,
    setIsLoading,
    errorMessage,
    setErrorMessage,
    refetchtInBackground,
    deleteVod,
  };
}

export default useManageVod;

export default function Landing1() {
  return (
    <section
      className="h-screen flex flex-col items-center bg-cover bg-center text-center relative"
      style={{
        backgroundImage: "url('/img/landing/PRIMERA PANTALLA - FONDO.jpg')",
      }}
    >
      <div className="absolute top-6 md:top-12 left-4 md:left-16">
        <img
          src="/img/Imagotipo Horizontal - A Color.svg"
          alt="Logo Baseados"
          className="h-8 md:h-12 w-auto"
        />
      </div>

      <div className="absolute top-12 right-16 hidden md:block">
        <a
          href="/login"
          className="px-8 py-3 bg-white text-black font-medium hover:bg-gray-300 text-lg transition-colors duration-200"
        >
          INICIA SESIÓN
        </a>
      </div>

      <div className="flex-1 flex flex-col justify-center mt-24 md:mt-48">
        <h1 className="text-5xl md:text-9xl font-bold text-white mb-4 font-anton tracking-wider outlined-text-6 uppercase px-4 md:px-0">
          DEPORTE BASE <br /> A TU ALCANCE
        </h1>
      </div>

      <div className="flex flex-col md:flex-row gap-4 md:gap-48 mb-16 px-4 md:px-0">
        <a
          href="#"
          className="w-full md:w-64 px-6 md:px-10 py-4 bg-secondary text-primary hover:bg-secondary-x-light uppercase font-bold text-lg md:text-xl transition-colors duration-200"
        >
          ÚNETE HOY
        </a>
        <a
          href="/login"
          className="w-full md:w-64 px-6 md:px-10 py-4 bg-primary text-secondary hover:bg-primary-dark uppercase font-bold text-lg md:text-xl transition-colors duration-200"
        >
          CLIENTES LIBERA
        </a>
      </div>
    </section>
  );
}

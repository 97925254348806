import { useState } from "react";

import Modal from "@components/Modal";

const teams = [
  {
    name: "Club Deportivo Lumbreras",
    description:
      "El fútbol base de Puerto Lumbreras tiene su casa en BASEADOS.TV",
    image: "/img/landing/team.png",
  },
  {
    name: "Club Deportivo Lumbreras",
    description:
      "El fútbol base de Puerto Lumbreras tiene su casa en BASEADOS.TV",
    image: "/img/landing/team.png",
  },
  {
    name: "Club Deportivo Lumbreras",
    description:
      "El fútbol base de Puerto Lumbreras tiene su casa en BASEADOS.TV",
    image: "/img/landing/team.png",
  },
  {
    name: "Club Deportivo Lumbreras",
    description:
      "El fútbol base de Puerto Lumbreras tiene su casa en BASEADOS.TV",
    image: "/img/landing/team.png",
  },
  {
    name: "Club Deportivo Lumbreras",
    description:
      "El fútbol base de Puerto Lumbreras tiene su casa en BASEADOS.TV",
    image: "/img/landing/team.png",
  },
];

export default function Landing4() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    club: "",
    message: "",
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const emailBody = `
  Nombre: ${formData.name}
  Email: ${formData.email}
  Club: ${formData.club}
  
  Mensaje:
  ${formData.message}
      `.trim();

    const mailtoUrl = `mailto:hola@baseados.tv?subject=Nuevo contacto desde BASEADOS&body=${encodeURIComponent(
      emailBody
    )}`;

    window.location.href = mailtoUrl;

    console.log("Form submitted:", formData);
    setIsModalOpen(false);
  };

  return (
    <section className="md:min-h-screen flex flex-col items-center justify-center bg-[#141414] text-center p-4 md:p-8">
      <h2 className="text-4xl md:text-7xl font-anton text-secondary mb-4 md:mb-6 italic uppercase">
        EQUIPOS
      </h2>
      <div className="relative w-full px-4 md:px-8">
        <button
          className="hidden md:block absolute left-0 top-1/2 -translate-y-1/2 z-10 bg-black/50 p-4 text-white hover:bg-black/70 transition-colors"
          onClick={() => {
            const carousel = document.querySelector(".teams-carousel");
            carousel?.scrollBy({
              left: -carousel.clientWidth,
              behavior: "smooth",
            });
          }}
        >
          ❮
        </button>

        <div
          className="teams-carousel flex gap-4 md:gap-12 w-full overflow-x-auto overflow-y-hidden snap-x snap-mandatory scroll-smooth"
          style={{
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            WebkitOverflowScrolling: "touch",
          }}
        >
          {teams.map((team) => (
            <div
              key={team.name}
              className="flex flex-col min-w-[calc(100%)] md:min-w-[calc(33.333%-2rem)] snap-start"
            >
              <div className="aspect-video">
                <img
                  src={team.image}
                  alt={team.name}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="bg-[#f7b81a] p-3 md:p-4">
                <h3 className="font-bold text-black uppercase italic text-lg md:text-xl mb-2">
                  {team.name}
                </h3>
                <p className="font-bold text-sm text-black text-left px-3 md:px-6 pb-2">
                  {team.description}
                </p>
              </div>
            </div>
          ))}
        </div>

        <button
          className="hidden md:block absolute right-0 top-1/2 -translate-y-1/2 z-10 bg-black/50 p-4 text-white hover:bg-black/70 transition-colors"
          onClick={() => {
            const carousel = document.querySelector(".teams-carousel");
            carousel?.scrollBy({
              left: carousel.clientWidth,
              behavior: "smooth",
            });
          }}
        >
          ❯
        </button>
      </div>

      <button
        className="mt-8 md:mt-12 px-4 md:px-8 py-3 md:py-4 bg-primary text-secondary font-bold text-base md:text-lg hover:bg-primary-dark transition-colors duration-200"
        onClick={() => setIsModalOpen(true)}
      >
        ¿QUIERES VER A TU CLUB EN BASEADOS? ¡CONTACTA!
      </button>

      <Modal isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col gap-4 min-w-[300px]"
        >
          <h3 className="text-2xl font-bold text-center mb-4">
            Contacta con nosotros
          </h3>

          <input
            type="text"
            placeholder="Nombre"
            className="border p-2 rounded"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            required
          />

          <input
            type="email"
            placeholder="Email"
            className="border p-2 rounded"
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
            required
          />

          <input
            type="text"
            placeholder="Club"
            className="border p-2 rounded"
            value={formData.club}
            onChange={(e) => setFormData({ ...formData, club: e.target.value })}
            required
          />

          <textarea
            placeholder="Mensaje"
            className="border p-2 rounded h-32"
            value={formData.message}
            onChange={(e) =>
              setFormData({ ...formData, message: e.target.value })
            }
            required
          />

          <button
            type="submit"
            className="bg-primary text-secondary font-bold py-2 px-4 rounded hover:bg-primary-dark transition-colors"
          >
            Enviar
          </button>
        </form>
      </Modal>
    </section>
  );
}

export const PRIMARY_COLOR_X_LIGHT = "#0d5349";
export const PRIMARY_COLOR_LIGHT = "#138076";
export const PRIMARY_COLOR = "#16a597";
export const PRIMARY_COLOR_DARK = "#1ac4b4";
export const PRIMARY_COLOR_X_DARK = "#4dd6ca";

export const SECONDARY_COLOR_X_LIGHT = "#b38313";
export const SECONDARY_COLOR_LIGHT = "#c69315";
export const SECONDARY_COLOR = "#ffbe22";
export const SECONDARY_COLOR_DARK = "#f9c64d";
export const SECONDARY_COLOR_X_DARK = "#fad77f";

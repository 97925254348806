import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import config from "@config";
import { API_BASE_URL, API_ROUTE_VODS_MANAGE } from "@constants";
import { SUCCESS_MESSAGE_DELAY } from "@constants/feedback";
import useManageVod, { IManageVodResponse } from "@hooks/live/useManageVod";
import { useAuthStore } from "@hooks/useAuthStore";
import loggedInTemplate from "@components/HOC/templates/loggedInTemplate";
import { withLiveAuth } from "@components/HOC/withAuth";
import Toggle from "@components/Toggle";
import Modal from "@components/Modal";
import FileUploader from "@components/FileUploader";
import ActionButton from "@components/ActionButton";

function VodDetailsManage() {
  const { id = "" } = useParams();
  const authStore = useAuthStore();

  useEffect(() => {
    document.title = `${config.public.appName} - Editar VOD`;
  }, []);

  const {
    vod,
    isLoading,
    setIsLoading,
    errorMessage,
    setErrorMessage,
    refetchtInBackground,
    deleteVod,
  }: IManageVodResponse = useManageVod(id);
  const [title, setTitle] = useState<string>(vod?.title || "");
  const [thumbnailFile, setThumbnailFile] = useState<File | null>(null);
  const [thumbnailUrl, setThumbnailUrl] = useState<string>(
    vod?.thumbnail || ""
  );
  const [summary, setSummary] = useState<string>(vod?.summary || "");
  const [visible, setVisible] = useState<boolean>(vod?.visible || false);
  const [size, setSize] = useState<string>(vod?.size || "");
  const [dateCreated, setDateCreated] = useState<string>(
    vod?.dateCreated || ""
  );
  const [hasSelectedThumbnail, setHasSelectedThumbnail] = useState(false);
  const [hasInfoChanged, setHasInfoChanged] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    if (vod) {
      setTitle(vod.title);
      setSummary(vod.summary);
      setThumbnailUrl(vod.thumbnail);
      setVisible(vod.visible);
      setSize(vod.size);
      setDateCreated(vod.dateCreated);
      setHasInfoChanged(false);
    }
  }, [vod]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log("Updating VOD...");

    const formData = new FormData();
    if (title) formData.append("title", title);
    if (summary) formData.append("summary", summary);
    formData.append("visible", visible.toString());
    if (thumbnailFile) {
      formData.append("vodThumbFile", thumbnailFile);
    }

    try {
      const response = await fetch(
        `${API_BASE_URL}${API_ROUTE_VODS_MANAGE}/${id}`,
        {
          method: "PATCH",
          body: formData,
          headers: {
            Authorization: `Bearer ${authStore.token}`,
          },
        }
      );

      if (response.ok) {
        setSuccessMessage("Video actualizado correctamente");
        refetchtInBackground();
      } else {
        const errorData = await response.json();
        setErrorMessage(errorData.message || "Error al actualizar el Video");
      }
    } catch (error) {
      console.error("Error updating VOD:", error);
      setErrorMessage("Error al actualizar el Video");
    } finally {
      setHasSelectedThumbnail(false);
      setTimeout(() => {
        setSuccessMessage("");
      }, SUCCESS_MESSAGE_DELAY);
    }
  };

  useEffect(() => {
    setHasInfoChanged(
      title !== vod?.title ||
        summary !== vod?.summary ||
        visible !== vod?.visible ||
        hasSelectedThumbnail
    );
  }, [title, summary, visible, hasSelectedThumbnail, vod]);

  const handleDelete = useCallback(async () => {
    console.log("Deleting VOD...");
    setIsDeleteModalOpen(false);
    setIsLoading(true);
    await deleteVod();
    setIsLoading(false);
  }, [deleteVod, setIsLoading]);

  return (
    <div className="relative w-full h-full mx-auto mt-8 mb-8 md:mb-0 overflow-y-auto max-h-screen p-4">
      <Modal isOpen={isDeleteModalOpen} setIsOpen={setIsDeleteModalOpen}>
        <div className="mb-8">¿Estás seguro de querer borrar el video?</div>
        <div className="flex justify-end">
          <button
            className="px-4 py-2 border border-gray-500 hover:bg-gray-300 rounded"
            onClick={() => setIsDeleteModalOpen(false)}
          >
            Mejor no
          </button>
          <button
            type="button"
            className="ml-4 px-4 py-2 bg-red-300 text-black rounded hover:bg-red-700 hover:text-white"
            onClick={handleDelete}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 inline-block mr-2"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z" />
            </svg>
            Borrar
          </button>
        </div>
      </Modal>
      <form onSubmit={handleSubmit} className="max-w-2xl mx-auto">
        {/* TITLE */}
        <div className="mb-8 flex items-center">
          <label htmlFor="title" className="mr-8">
            Título{title ? "*" : <span className="text-red-500">*</span>}
          </label>
          <input
            type="text"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-3/4 px-3 py-2 border rounded"
          />
        </div>

        {/* SIZE AND DATE CREATED */}
        <div className="mb-8 bg-gray-100 p-4 rounded-lg shadow-sm">
          <div className="flex justify-between">
            <div>
              <p className="text-sm text-gray-600">Tamaño</p>
              <p className="text-md font-medium">{size || "N/A"}</p>
            </div>
            <div>
              <p className="text-sm text-gray-600">Fecha de creación</p>
              <p className="text-md font-medium">
                {dateCreated
                  ? new Date(dateCreated).toLocaleDateString("es-ES", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    })
                  : "N/A"}
              </p>
            </div>
          </div>
        </div>

        {/* THUMBNAIL */}
        <div className="mb-4 flex items-start">
          <label htmlFor="thumbnail" className="w-1/8 mr-8 text-right">
            Miniatura
          </label>
          <div className="w-3/4 px-3 py-2 flex">
            {thumbnailUrl && (
              <div className="md:w-1/2 lg:w-1/4 mr-8">
                <img
                  src={API_BASE_URL + thumbnailUrl}
                  alt="Thumbnail"
                  className="h-full rounded"
                />
              </div>
            )}
            <FileUploader
              name="thumbnail"
              accept="image/*"
              file={thumbnailFile}
              setFile={setThumbnailFile}
              message="Seleccionar"
              onChange={() => setHasSelectedThumbnail(true)}
            />
          </div>
        </div>

        {/* SUMMARY */}
        <div className="mb-8">
          <label htmlFor="summary" className="block mb-2">
            Descripción
          </label>
          <textarea
            id="summary"
            value={summary}
            onChange={(e) => setSummary(e.target.value)}
            className="w-full px-3 py-2 border rounded"
          />
        </div>

        {/* VISIBILITY */}
        <div className="mb-8 flex items-center justify-between">
          <Toggle
            checked={visible}
            onClick={() => setVisible((checked) => !checked)}
            checkedLabel="Visible"
            uncheckedLabel="Oculto"
          />
          <button
            type="button"
            className="ml-4 px-4 py-2 bg-red-300 text-black rounded hover:bg-red-700 hover:text-white"
            onClick={() => setIsDeleteModalOpen(true)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 inline-block mr-2"
              viewBox="0 0 24 24"
              fill="currentColor"
            >
              <path d="M3 6v18h18v-18h-18zm5 14c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm5 0c0 .552-.448 1-1 1s-1-.448-1-1v-10c0-.552.448-1 1-1s1 .448 1 1v10zm4-18v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.315c0 .901.73 2 1.631 2h5.712z" />
            </svg>
            Eliminar el Video
          </button>
        </div>

        <ActionButton
          isLoading={isLoading}
          successMessage={successMessage}
          errorMessage={errorMessage}
          enabled={hasInfoChanged}
          mandatoryFields={[title]}
        >
          Actualizar VOD
        </ActionButton>
      </form>
    </div>
  );
}

export default withLiveAuth(loggedInTemplate(VodDetailsManage));

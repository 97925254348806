import { useCallback, useEffect, useState } from "react";
import { HTTP_GENERAL_ERROR } from "@constants/network";
import { API_BASE_URL } from "@constants";
import { useAuthStore } from "@hooks/useAuthStore";

export interface IRequestProps {
  url: string;
  method: string;
  body?: any;
}

export interface IRequestResponse<T> {
  data: {
    [key: string]: T | null;
  };
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  errorMessage: string;
  setErrorMessage: (errorMessage: string) => void;
  refetchtInBackground: () => Promise<void>;
}

function fetchData(
  token: string,
  url: string,
  method: string,
  body?: any
): Promise<Response> {
  return fetch(`${API_BASE_URL}${url}`, {
    method: method,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: body ? JSON.stringify(body) : undefined,
  });
}

export function useRequest<T>(props: IRequestProps): IRequestResponse<T> {
  const { url, method, body } = props;

  const authStore = useAuthStore();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setData] = useState<{ [key: string]: T | null } | null>(null);

  const getData = useCallback(async () => {
    if (!authStore.token || !url || !method) {
      setData(null);
      setErrorMessage("");
      return;
    }

    try {
      setIsLoading(true);
      setErrorMessage("");
      const response = await fetchData(authStore.token, url, method, body);
      const responseData = await response.json();
      if (response.ok) {
        setData(responseData.data);
      } else {
        setErrorMessage(responseData.message || HTTP_GENERAL_ERROR);
      }
    } catch (error) {
      setErrorMessage(HTTP_GENERAL_ERROR);
    } finally {
      setIsLoading(false);
    }
  }, [authStore.token, url, method, body]);

  const getDataInTheBackground = useCallback(async () => {
    if (!authStore.token || !url || !method) {
      setData(null);
      return;
    }

    const response = await fetchData(authStore.token, url, method, body);
    const responseData = await response.json();
    if (response.ok) {
      setData(responseData.data);
    }
  }, [authStore.token, url, method, body]);

  useEffect(() => {
    getData();
  }, [getData]);

  const refetchtInBackground = useCallback(() => {
    return getDataInTheBackground();
  }, [getDataInTheBackground]);

  return {
    data: data || {},
    isLoading,
    setIsLoading,
    errorMessage,
    setErrorMessage,
    refetchtInBackground,
  };
}

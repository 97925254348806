import { API_ROUTE_VODS_LIST } from "@constants";
import { useRequest } from "./useRequest";
import { IVod } from "./useVod";

export interface IVodsListResponse {
  vodsList: IVod[] | null;
  isLoading: boolean;
  errorMessage: string;
}

export function useVodsList(): IVodsListResponse {
  const { data, isLoading, errorMessage } = useRequest<IVod[]>({
    url: API_ROUTE_VODS_LIST,
    method: "GET",
  });

  return { vodsList: data?.vods, isLoading, errorMessage };
}

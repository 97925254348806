import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Index from "@pages/Index";
import Login from "@pages/Login";
import Profile from "@pages/Profile";
import LiveList from "@pages/LiveList";
import LiveStream from "@pages/LiveStream";
import VodsList from "@pages/VodsList";
import Vod from "@pages/Vod";
import StreamProfile from "@pages/live/StreamProfile";
import VodCreate from "@pages/live/VodCreate";
import VodsManage from "@pages/live/VodsManage";
import VodDetailsManage from "@pages/live/VodDetailsManage";

import {
  WEB_ROUTE_LANDING,
  WEB_ROUTE_LOGIN,
  WEB_ROUTE_PROFILE,
  WEB_ROUTE_STREAM_PROFILE,
  WEB_ROUTE_LIVE_EVENTS,
  WEB_ROUTE_VODS,
  WEB_ROUTE_VOD_CREATE,
  WEB_ROUTE_VODS_MANAGE,
} from "@constants";

function AppRoutes() {
  return (
    <Router>
      <Routes>
        {/* PUBLIC ROUTES */}
        <Route path={WEB_ROUTE_LANDING} element={<Index />} />
        <Route path={WEB_ROUTE_LOGIN} element={<Login />} />
        <Route path={WEB_ROUTE_PROFILE} element={<Profile />} />
        <Route path={WEB_ROUTE_LIVE_EVENTS} element={<LiveList />} />
        <Route
          path={`${WEB_ROUTE_LIVE_EVENTS}/:slug`}
          element={<LiveStream />}
        />
        <Route path={WEB_ROUTE_VODS} element={<VodsList />} />
        <Route path={`${WEB_ROUTE_VODS}/:id`} element={<Vod />} />
        {/* ADMIN ROUTES */}
        <Route path={WEB_ROUTE_STREAM_PROFILE} element={<StreamProfile />} />
        <Route path={WEB_ROUTE_VOD_CREATE} element={<VodCreate />} />
        <Route path={WEB_ROUTE_VODS_MANAGE} element={<VodsManage />} />
        <Route
          path={`${WEB_ROUTE_VODS_MANAGE}/:id`}
          element={<VodDetailsManage />}
        />
      </Routes>
    </Router>
  );
}

export default AppRoutes;

const config = {
  app: {
    head: {
      title: "TVCool | Unleash all streaming potential",
      charset: "utf-8",
      viewport: "width=device-width, initial-scale=1",
    },
  },
  public: {
    apiUrl: process.env.REACT_APP_API_BASE_URL,
    appName: "Baseados.tv",
  },
};

export default config;
